<template>
    <b-card>
      <app-collapse>
        <app-collapse-item
          v-for="(s_doc, doc_index) in sample_documents"
          :key="'sample_doc_' + doc_index"
          :title="s_doc.country_name"
        >
          <b-row>
            <b-col
              v-for="(doc, index) in s_doc.docs"
              :key="'doc_' + index"
              cols="2"
            >
              <span>{{ doc.s_doc_name }}</span>
              <br />
              <embed
                :src="$FILESURL + doc.s_doc_url"
                width="100%"
                height="200px"
              />
              <br />
              <span> <a :href="$FILESURL + doc.s_doc_url">open</a> </span>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </template>
  
  <script>
  import { BCard, BCol, BRow } from "bootstrap-vue";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  
  import CommonServices from '@/apiServices/CommonServices';
  
  export default {
    components: { BCard, AppCollapse, AppCollapseItem, BCol, BRow },
    data() {
      return {
        sample_documents: [],
      };
    },
    methods: {
      async getSampleDocuments() {
        try {
          const response = await CommonServices.getSampleDocuments();
          if (response.data.status) {
            this.sample_documents = response.data.data;
          }
        } catch (err) {
          console.log("Error in getting  ", err);
        }
      },
    },
    beforeMount() {
      this.getSampleDocuments();
    },
  };
  </script>
  
  <style>
  </style>